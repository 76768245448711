<template>
  <v-autocomplete
    class="search-bak"
    :items="institutions.filter((el) => el.isActive != active)"
    @input="input"
    hide-no-data
    item-text="name"
    item-value="_id"
    placeholder="Start typing to find institutions"
    ref="autocomplete"
    cache-items
    autocomplete="off"
    v-on="listeners"
    v-bind="attrs"
  />
</template>
<script>
import CoreMixin from '@/mixins/core.mixin';
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'InstitutionPicker',

  mixins: [CoreMixin],

  props: {
    active: {
      type: Boolean,
      default: null
    }
  },

  async mounted() {
    try {
      await this.fetchInstitutions();
    } catch (error) {
      this.httpErrorHandler(error);
    }
  },

  computed: {
    listeners() {
      const { ...listeners } = this.$listeners;
      return listeners;
    },

    attrs() {
      const { ...attrs } = this.$attrs;
      return attrs;
    },
    ...mapGetters('institutionModule', ['institutions'])
  },

  methods: {
    ...mapActions('institutionModule', ['fetchInstitutions']),
    input(event) {
      this.$emit('input', event);
    }
  }
};
</script>

