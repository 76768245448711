var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"px-0 ma-0",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"col-12 pa-0"},[_c('ConfirmationModal',{ref:"activeToggleConfirmationModal",attrs:{"title":_vm.confirmationTitle,"subtitle":"","message":_vm.confirmationMessage,"confirmColor":"error darken-1"}}),_c('ModalFormLayout',{ref:"userForm",attrs:{"options":{
        color: 'white',
        title: _vm.formTitle,
        subtitle: _vm.formSubTitle
      },"loading":_vm.loading},on:{"cancel":_vm.onClose,"submit":_vm.onSubmit},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('ValidationObserver',{ref:"userObserver"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"username","rules":"required|max:80","events":"['blur']"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"User Full Name","error-messages":errors},model:{value:(_vm.appUser.name),callback:function ($$v) {_vm.$set(_vm.appUser, "name", $$v)},expression:"appUser.name"}})]}}])})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"role","rules":"required","events":"['blur']"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.roles,"item-value":"code","item-text":"text","label":"Role","error-messages":errors},model:{value:(_vm.appUser.role),callback:function ($$v) {_vm.$set(_vm.appUser, "role", $$v)},expression:"appUser.role"}})]}}])})],1),(_vm.isInstitutionUser)?_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"institution","rules":("" + (_vm.isInstitutionUser ? 'required' : '')),"events":"['blur']"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('InstitutionPicker',{attrs:{"label":"Assigned Institution","error-messages":errors},model:{value:(_vm.appUser.institution),callback:function ($$v) {_vm.$set(_vm.appUser, "institution", $$v)},expression:"appUser.institution"}})]}}],null,false,1823442924)})],1):_vm._e(),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"contact number","rules":"required|telephone","events":"['blur']"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Contact number","placeholder":"+8760000000","error-messages":_vm.userContactNumberExistErr || errors},on:{"blur":_vm.checkIfUserExist},model:{value:(_vm.appUser.contactNumber),callback:function ($$v) {_vm.$set(_vm.appUser, "contactNumber", $$v)},expression:"appUser.contactNumber"}})]}}])})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email","events":"['blur']"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Email","error-messages":_vm.userEmailExistErr || errors},on:{"blur":_vm.checkIfUserExist},model:{value:(_vm.appUser.email),callback:function ($$v) {_vm.$set(_vm.appUser, "email", $$v)},expression:"appUser.email"}})]}}])})],1)],1)],1)]},proxy:true}])}),_c('DataTableLayout',{attrs:{"options":_vm.layoutOptions,"loading":_vm.pageLoading},on:{"search":function($event){_vm.search = $event},"add":_vm.openForm},scopedSlots:_vm._u([{key:"actions",fn:function(){return undefined},proxy:true},{key:"content",fn:function(){return [_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"loading":_vm.pageLoading,"items":_vm.users,"search":_vm.search,"loading-text":"Searching... Please wait"},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('NoData'),_c('h2',{staticClass:"mb-12 subtitle-1"},[_vm._v("No data found")])]},proxy:true},{key:"item.isActive",fn:function(ref){
      var item = ref.item;
return [_c('v-checkbox',{on:{"click":function($event){return _vm.enableOrDisableUser(item)}},model:{value:(item.isActive),callback:function ($$v) {_vm.$set(item, "isActive", $$v)},expression:"item.isActive"}})]}},{key:"item.name",fn:function(ref){
      var item = ref.item;
return [_c('b',[_vm._v(_vm._s(_vm._f("capitalize")(item.name)))])]}},{key:"item.email",fn:function(ref){
      var item = ref.item;
return [_c('a',{attrs:{"href":("mailto: " + (item.email))}},[_vm._v(_vm._s(item.email))])]}},{key:"item.contactNumber",fn:function(ref){
      var item = ref.item;
return [_c('a',{attrs:{"href":("tel: " + (item.contactNumber))}},[_vm._v(_vm._s(item.contactNumber || 'N/A'))])]}},{key:"item.role",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("capitalize")(item.role ? item.role : '-'))+" ")]}},{key:"item.institution",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("capitalize")(item.institution ? item.institution.name : '-'))+" ")]}},{key:"item.actions",fn:function(ref){
      var index = ref.index;
      var item = ref.item;
return [_c('DataTableActions',{attrs:{"actions":_vm.actions,"data":{ index: index, item: item }}})]}}],null,true)})]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }