<template>
  <v-row justify="center" class="px-0 ma-0">
    <v-col class="col-12 pa-0">
      <!-- Modals -->
      <ConfirmationModal
        ref="activeToggleConfirmationModal"
        :title="confirmationTitle"
        subtitle=""
        :message="confirmationMessage"
        confirmColor="error darken-1"
      />

      <ModalFormLayout
        :options="{
          color: 'white',
          title: formTitle,
          subtitle: formSubTitle
        }"
        @cancel="onClose"
        @submit="onSubmit"
        :loading="loading"
        ref="userForm"
      >
        <template #content>
          <ValidationObserver ref="userObserver">
            <v-row dense>
              <v-col cols="12" class="py-0">
                <ValidationProvider
                  name="username"
                  rules="required|max:80"
                  events="['blur']"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    label="User Full Name"
                    :error-messages="errors"
                    v-model="appUser.name"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>

              <v-col cols="12" class="py-0">
                <ValidationProvider
                  name="role"
                  rules="required"
                  events="['blur']"
                  v-slot="{ errors }"
                >
                  <v-autocomplete
                    :items="roles"
                    item-value="code"
                    item-text="text"
                    label="Role"
                    :error-messages="errors"
                    v-model="appUser.role"
                  />
                </ValidationProvider>
              </v-col>

              <v-col cols="12" v-if="isInstitutionUser" class="py-0">
                <ValidationProvider
                  name="institution"
                  :rules="`${isInstitutionUser ? 'required' : ''}`"
                  events="['blur']"
                  v-slot="{ errors }"
                >
                  <InstitutionPicker
                    label="Assigned Institution"
                    :error-messages="errors"
                    v-model="appUser.institution"
                  />
                </ValidationProvider>
              </v-col>

              <v-col cols="6" class="py-0">
                <ValidationProvider
                  name="contact number"
                  rules="required|telephone"
                  events="['blur']"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    label="Contact number"
                    placeholder="+8760000000"
                    @blur="checkIfUserExist"
                    :error-messages="userContactNumberExistErr || errors"
                    v-model="appUser.contactNumber"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>

              <v-col cols="6" class="py-0">
                <ValidationProvider
                  name="email"
                  rules="required|email"
                  events="['blur']"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    label="Email"
                    @blur="checkIfUserExist"
                    :error-messages="userEmailExistErr || errors"
                    v-model="appUser.email"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
          </ValidationObserver>
        </template>
      </ModalFormLayout>

      <DataTableLayout
        @search="search = $event"
        :options="layoutOptions"
        @add="openForm"
        :loading="pageLoading"
      >
        <!-- Use to add addition actions such as buttons-->
        <template #actions> </template>

        <!-- content to display -->
        <template #content>
          <v-data-table
            class="elevation-0"
            :headers="headers"
            :loading="pageLoading"
            :items="users"
            :search="search"
            loading-text="Searching... Please wait"
          >
            <template v-slot:no-data>
              <NoData />
              <h2 class="mb-12 subtitle-1">No data found</h2>
            </template>

            <template v-slot:[`item.isActive`]="{ item }">
              <v-checkbox
                v-model="item.isActive"
                @click="enableOrDisableUser(item)"
              ></v-checkbox>
            </template>
            <template v-slot:[`item.name`]="{ item }">
              <b>{{ item.name | capitalize }}</b>
            </template>
            <template v-slot:[`item.email`]="{ item }">
              <a :href="`mailto: ${item.email}`">{{ item.email }}</a>
            </template>
            <template v-slot:[`item.contactNumber`]="{ item }">
              <a :href="`tel: ${item.contactNumber}`">{{
                item.contactNumber || 'N/A'
              }}</a>
            </template>
            <template v-slot:[`item.role`]="{ item }">
              {{ item.role ? item.role : '-' | capitalize }}
            </template>
            <template v-slot:[`item.institution`]="{ item }">
              {{ item.institution ? item.institution.name : '-' | capitalize }}
            </template>
            <template v-slot:[`item.actions`]="{ index, item }">
              <DataTableActions :actions="actions" :data="{ index, item }" />
            </template>
          </v-data-table>
        </template>
      </DataTableLayout>
    </v-col>
  </v-row>
</template>

<style scoped>
.v-expansion-panel-content >>> .v-expansion-panel-content__wrap {
  padding: 0px !important;
}
</style>

<script>
import PageMixin from '@/mixins/page.mixin';
import { mapActions, mapGetters } from 'vuex';
import InstitutionPicker from '@/components/pickers/InstitutionPicker.vue';

export default {
  components: { InstitutionPicker },
  mixins: [PageMixin],
  data: () => ({
    panel: false,
    pageLoading: false,
    loading: false,
    search: '',
    appUser: {},
    formTitle: 'Create user',
    formSubTitle: '',

    confirmationTitle: '',
    confirmationMessage: '',

    layoutOptions: {
      hideSearch: false, // removes search button totally from view
      showSearch: false, // toggle search bar
      buttonText: 'Create User',
      title: 'User Management',
      subtitle: 'Lists authorized users.'
    },

    roles: [
      { code: 'ADMIN', text: 'System Administrator' },
      { code: 'INSTITUTION-USER', text: 'Institution User' }
    ],
    actions: [],
    userEmailExistErr: '',
    userContactNumberExistErr: '',

    headers: [
      {
        text: 'Enabled',
        align: 'start',
        filterable: false,
        value: 'isActive'
      },
      {
        text: 'Name',
        align: 'start',
        filterable: true,
        value: 'name'
      },
      { text: 'Email', value: 'email', filterable: true },
      { text: 'Contact Number', value: 'contactNumber', filterable: true },
      { text: 'Role', value: 'role', align: 'center', filterable: true },
      { text: 'Institution', value: 'institution' },
      { text: 'Actions', align: 'end', value: 'actions', sortable: false }
    ]
  }),

  async mounted() {
    this.actions = [
      {
        icon: 'mdi-pencil-outline',
        action: 'Edit',
        actionCallBack: (index, item) => {
          this.openForm(item);
        }
      },
      {
        icon: 'mdi-refresh',
        action: 'New Password',
        actionCallBack: (index, item) => {
          this.generateNewPassword(item);
        }
      },
      {
        icon: 'mdi-pail-remove-outline',
        action: 'Delete',
        actionCallBack: (index, item) => {
          this.removeUser(item);
        }
      }
    ];

    try {
      this.pageLoading = true;
      await this.loadUserPage();
      console.log(this.users);
    } catch (error) {
      this.httpErrorHandler(error);
    } finally {
      this.pageLoading = false;
    }
  },

  computed: {
    ...mapGetters('userModule', ['users']),
    isInstitutionUser: function () {
      return this.appUser.role == 'INSTITUTION-USER';
    }
  },

  methods: {
    ...mapActions('userModule', [
      'createUser',
      'updateUser',
      'loadUserPage',
      'deleteUser',
      'generatePassword'
    ]),

    checkIfUserExist() {
      this.userEmailExistErr = '';
      this.userContactNumberExistErr = '';
      let exist = this.users.some(
        (el) => el.email === this.appUser.email && this.appUser._id !== el._id
      );
      if (exist) {
        this.userEmailExistErr = `User with the email ${this.appUser.email} already exist`;
      }

      exist = this.users.some(
        (el) =>
          el.contactNumber === this.appUser.contactNumber &&
          this.appUser._id !== el._id
      );
      if (exist) {
        this.userContactNumberExistErr = `User with the contact number ${this.appUser.contactNumber} already exist`;
      }
    },

    openForm(item) {
      this.formTitle = 'Create User';
      this.formSubTitle = 'a new user';
      if (item?._id) {
        this.appUser = { ...item };
        if (this.appUser.institution) {
          this.appUser.institution = this.appUser.institution._id;
        }
        this.formTitle = 'Update User';
        this.formSubTitle = `editing ${item.name} user account`;
      }
      this.$refs.userForm.open();
    },

    onClose() {
      this.$refs.userObserver?.reset();
      this.appUser = {};
      this.userEmailExistErr = '';
      this.userContactNumberExistErr = '';
    },

    removeUser(item) {
      const numberOfAdminUser = this.users.filter(
        (el) => el.role == 'ADMIN'
      ).length;
      if (numberOfAdminUser == 1 && item.role == 'ADMIN') {
        this.showSnack({
          color: 'warning',
          title: `Unable to delet user`,
          message: `Cannot delete the only admin user in the system.`
        });
        return;
      }
      this.confirmationTitle = 'Are you sure you want to delete this user?';
      this.confirmationMessage =
        'This action will prevent the user from accessing the applications';

      this.$refs.activeToggleConfirmationModal.show(async () => {
        try {
          await this.deleteUser(item._id);
          this.showSnack({
            color: 'info',
            title: `Removed`,
            message: `User ${item.name} was successfully removed.`
          });
        } catch (error) {
          this.httpErrorHandler(error);
        }
      });
    },

    enableOrDisableUser(item) {
      this.confirmationTitle = 'Are you sure you want to deactive this user?';
      this.confirmationMessage =
        'This action may lead to persons unable to log into the system';
      if (item.isActive) {
        this.confirmationTitle = 'Are you sure you want to active this user?';
        this.confirmationMessage =
          'This action may allow unintended persons to log into the system';
      }

      this.$refs.activeToggleConfirmationModal.show(
        async () => {
          //This executes if user selects yes
          try {
            this.appUser = { ...item };
            if (this.appUser.institution) {
              this.appUser.institution = this.appUser.institution?._id;
            }
            await this.onSubmit();
          } catch {
            item.isActive = !item.isActive;
          }
        },
        () => {
          //This executes if user selects no
          item.isActive = !item.isActive;
        }
      );
    },

    generateNewPassword(item) {
      this.confirmationTitle = `You are about to generate a new password for ${item.name}.`;
      this.confirmationMessage =
        'Are you sure you want to perform this action.';

      this.$refs.activeToggleConfirmationModal.show(async () => {
        try {
          await this.generatePassword(item._id);
          this.showSnack({
            color: 'info',
            title: `Password Generated`,
            message: `User ${item.name} password was successfully changed.`
          });
        } catch (error) {
          this.httpErrorHandler(error);
        }
      });
    },

    async onSubmit() {
      if (this.$refs.userObserver) {
        const isValid = await this.$refs.userObserver.validate();
        if (
          !isValid ||
          this.userEmailExistErr ||
          this.userContactNumberExistErr
        )
          return;
      }

      this.loading = true;

      const isUpdating = !!this.appUser._id;

      try {
        if (!isUpdating) {
          this.appUser.shouldChangePassword = true;
          await this.createUser(this.appUser);
        } else {
          this.appUser.shouldChangePassword = false;

          await this.updateUser({
            id: this.appUser._id,
            payload: this.appUser
          });
        }

        this.showSnack({
          color: 'success',
          title: `${isUpdating ? 'Updated' : 'Created'}`,
          message: `User was successfully ${
            isUpdating ? 'updated' : 'created.'
          }.`
        });

        this.onClose();
        this.$refs.userForm.close();
        this.userEmailExistErr = '';
        this.userContactNumberExistErr = '';
      } catch (error) {
        this.httpErrorHandler(error);
        throw error;
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>